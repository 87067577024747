export default [
    {
        path: "/sipengawas/penugasan",
        name: "sipengawas.penugasan",
        component: () => import("@/pages/sipengawas/penugasan/Penugasan.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Penugasan",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master/perencanaan-strategi-pengawasan",
        name: "perencanaanStrategiPengawasan",
        component: () => import("@/pages/master/perencanaan-strategi-pengawasan/PerencanaanStrategiPengawasan.vue"),
        meta: {
            pageTitle: "Perencanaan Startegi Pengawasan",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master/jenis-pengawasan",
        name: "jenisPengawasan",
        component: () => import("@/pages/master/jenis-pengawasan/JenisPengawasan.vue"),
        meta: {
            pageTitle: "Jenis Pengawasan",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/level",
        name: "sipengawas.level",
        component: () => import("@/pages/master-sipengawas/level/Level.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Level",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/master-sipengawas/bagian",
        name: "sipengawas.bagian",
        component: () => import("@/pages/master-sipengawas/bagian/Bagian.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Bagian",
            icon: "icon-[tabler--building]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/itban",
        name: "sipengawas.itban",
        component: () => import("@/pages/master-sipengawas/itban/Itban.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Itban",
            icon: "icon-[ic--outline-work-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/jabatan",
        name: "sipengawas.jabatan",
        component: () => import("@/pages/master-sipengawas/jabatan/Jabatan.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Jabatan",
            icon: "icon-[tabler--user-shield]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/pegawai",
        name: "sipengawas.pegawai",
        component: () => import("@/pages/master-sipengawas/pegawai/Pegawai.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Pegawai",
            icon: "icon-[tabler--users]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/opd",
        name: "sipengawas.opd",
        component: () => import("@/pages/master-sipengawas/opd/Opd.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "OPD/Unit",
            icon: "icon-[ph--buildings]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/hiper-aktif",
        name: "sipengawas.hiperAktif",
        component: () => import("@/pages/master-sipengawas/hiper-aktif/HiperAktif.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Hiper Aktif",
            icon: "icon-[mdi--flash-alert-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/jenis",
        name: "sipengawas.jenis",
        component: () => import("@/pages/master-sipengawas/jenis/Jenis.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Jenis",
            icon: "icon-[mdi--shape-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/kegiatan",
        name: "sipengawas.kegiatan",
        component: () => import("@/pages/master-sipengawas/kegiatan/Kegiatan.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Kegiatan",
            icon: "icon-[ic--outline-directions-run]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/resiko",
        name: "sipengawas.resiko",
        component: () => import("@/pages/master-sipengawas/resiko/Resiko.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Resiko",
            icon: "icon-[ic--baseline-broken-image]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/surat",
        name: "sipengawas.surat",
        component: () => import("@/pages/master-sipengawas/surat/Surat.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Surat",
            icon: "icon-[tabler--mail]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/tingkat-resiko",
        name: "sipengawas.tingkatResiko",
        component: () => import("@/pages/master-sipengawas/tingkat-resiko/TingkatResiko.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Tingkat Manajemen Resiko",
            icon: "icon-[tabler--alert-hexagon]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/klien",
        name: "sipengawas.klien",
        component: () => import("@/pages/master-sipengawas/klien/Klien.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Klien",
            icon: "icon-[tabler--alert-hexagon]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/dasar-penugasan",
        name: "sipengawas.dasarPenugasan",
        component: () => import("@/pages/master-sipengawas/dasar-penugasan/DasarPenugasan.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Dasar Penugasan",
            icon: "icon-[tabler--alert-hexagon]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master-sipengawas/biaya",
        name: "sipengawas.biaya",
        component: () => import("@/pages/master-sipengawas/biaya/Biaya.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: 'menu-sipengawas',
            pageTitle: "Biaya",
            icon: "icon-[tabler--alert-hexagon]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
];
