import { createWebHistory, createRouter } from "vue-router";
import redirect from "./redirect";
import master from "./master";
import masterSipengawas from "./master-sipengawas";
import account from "./account";
import guest from "./guest";
import pkpt from "./pkpt";
import sipengawas from "./sipengawas";
import ability from "@/plugins/casl/ability";
import { nextTick } from "vue";

const routes = [
    ...redirect,
    ...master,
    ...account,
    ...guest,
    ...pkpt,
    ...sipengawas,
    ...masterSipengawas,
    {
        path: "/home",
        name: "home",
        component: () => import("@/pages/home/Home.vue"),
        meta: {
            pageTitle: "Home",
            icon: "icon-[tabler--home]",
            layout: "LayoutHome",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/all-dashboard",
        name: "allDashboard",
        component: () => import("@/pages/all-dashboard/Dashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            icon: "icon-[tabler--layout-dashboard]",
            layout: "LayoutHome",
            resource: "pelaksana",
            action: "read",
        },
    },
];

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (from.meta.savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const hasLogin = ability.can('read', 'auth');
    const canNavigate = to.matched.some((route) => {
        if (Array.isArray(route.meta.resource)) {
            let resource = route.meta.resource;
            const result = resource.some((r) => {
                return ability.can(route.meta.action || "read", r);
            });
            return result;
        } else {
            return ability.can(
                route.meta.action || "read",
                route.meta.resource
            );
        }
    });
    if (!canNavigate) {
        return next("/not-authorized");
    }
    if (hasLogin && to.name === 'login') {
        next({ name: 'home', replace: true });
        return
    }
    next();
});

router.afterEach((to, from) => {
    nextTick().then(() => {
        // window.scrollTo(0, 0);
        document.title =
            to.meta.pageTitle + " - Look At" || "Look At";
    });
});

export default router;
