export default [
    {
        path: "/sipengawas/dashboard",
        name: "sipengawas",
        component: () => import("@/pages/sipengawas/dashboard/Dashboard.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: "menu-sipengawas",
            pageTitle: "Dashboard",
            icon: "icon-[ic--outline-dashboard]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/sipengawas/penugasan",
        name: "sipengawas.penugasan",
        component: () => import("@/pages/sipengawas/penugasan/Penugasan.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: "menu-sipengawas",
            pageTitle: "Penugasan",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/sipengawas/laporan-penugasan",
        name: "sipengawas.laporanPenugasan",
        component: () =>
            import("@/pages/sipengawas/laporan-penugasan/LaporanPenugasan.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: "menu-sipengawas",
            pageTitle: "Laporan Penugasan",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/sipengawas/:ulid",
        name: "Cetak",
        component: () => import("@/pages/sipengawas/surat/ContainerSurat.vue"),
        meta: {
            subApp: "SiPengawas",
            menu: "menu-sipengawas",
            pageTitle: "Surat Perintah (Paraf)",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
        children: [
            {
                path: "sp-paraf",
                name: "CetakSpParaf",
                component: () => import("@/pages/sipengawas/surat/SpParaf.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
            },
        ],
    },
];
