const menu = [
    // {
    //     name: "home",
    //     icon: "icon-[tabler--home]",
    //     title: "Beranda",
    //     access: "auth",
    //     type: "btn",
    // },
    {
        name: "pkpt",
        icon: "icon-[tabler--clipboard-text]",
        title: "PKPT",
        subtitle: "Sistem Informasi Program Kerja Pengawasan Tahunan",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "sipengawas",
        icon: "icon-[ic--round-security]",
        title: "SiPengawas",
        subtitle:
            "Sistem Informasi Penugasan Pengawasan Inspektorat Kabupaten Bogor",
        access: "pelaksana",
        type: "btn",
    },
    // {
    //     icon: "icon-[tabler--database]",
    //     title: "Master",
    //     access: "pelaksana",
    //     type: "dropdown",
    //     list: [
    //         {
    //             name: "level",
    //             icon: "icon-[tabler--user-up]",
    //             title: "Level User",
    //             access: "pelaksana",
    //         },
    //     ],
    // },
];

export default menu;
