<template>
    <v-app class="overflow-hidden" id="layout">
        <v-defaults-provider :defaults="defaultProvider">
            <v-navigation-drawer
                v-model="drawer"
                location="left"
                flat
                temporary
                :width="$vuetify.display.smAndUp ? 340 : 600"
                class="py-5 custom-nav"
            >
                <div class="h-full d-flex flex-col justify-between">
                    <!-- menu component -->
                    <MenuHome></MenuHome>
                </div>
            </v-navigation-drawer>

            <v-app-bar
                app
                flat
                class="drop-shadow-xl"
                :class="{
                    '!border-b-[1px] !border-[#2e3c34]':
                        $vuetify.theme.current.dark,
                }"
                :height="80"
                style="z-index: 100"
                scroll-behavior="hide inverted"
                scroll-threshold="280"
            >
                <navigation-bar
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
            </v-app-bar>
            <!-- color="#52bc94" -->
            <v-app-bar absolute flat class="drop-shadow-xl" :height="80">
                <navigation-bar
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
            </v-app-bar>

            <v-main id="main" class="layout-page-content">
                <router-view v-slot="{ Component }">
                    <v-slide-y-transition mode="out-in">
                        <component :is="Component" />
                    </v-slide-y-transition>

                    <!-- <transition name="page">
                        <component :is="Component" />
                    </transition> -->
                </router-view>
                <data class="h-20"></data>
            </v-main>
        </v-defaults-provider>
    </v-app>
</template>

<script setup>
import { VFab } from "vuetify/labs/VFab";
import { asset, replaceAvaByDefault } from "@/mixins";
import MenuHome from "./menu/MenuHome.vue";
import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import NavigationBar from "./NavigationBarHome.vue";
import BottomAppBar from "./component/BottomAppBar.vue";
import {
    ref,
    onMounted,
    computed,
    provide,
    reactive,
    onBeforeUnmount,
} from "vue";
import { useTheme, useDisplay } from "vuetify";
import ability from "@/plugins/casl/ability";

import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();

const theme = useTheme();
const { xs, lgAndUp } = useDisplay();
const isDark = computed(() => {
    let current = theme.current.value;
    return current.dark;
});

const isDisplay = computed(() => {
    return xs;
});

provide("$isDark", isDark);

const defaultProvider = reactive({
    VCard: {
        border: isDark,
    },
    VToolbar: {
        border: isDark,
    },
    VDialogFull: {
        fullscreen: xs,
        persistent: true,
        scrollable: true,
    },
});

const drawer = ref(false);
const offsetTop = ref(0);

const handleDrawer = () => {
    drawer.value = !drawer.value;
};

const onScroll = () => {
    offsetTop.value = window.top.scrollY;
};

const offline = ref(false);
function handleConnectionChange() {
    offline.value = !navigator.onLine;
}

const banner = ref(false);
provide("$banner", banner);
let deferredPrompt = ref(window._deferredPrompt);
provide("$deferredPrompt", deferredPrompt);

async function installApp() {
    banner.value = false;
    if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === "accepted") {
            deferredPrompt.value = null;
        } else {
            deferredPrompt.value = window._deferredPrompt;
        }
    }
}

function installLater() {
    localStorage.setItem("installLater", JSON.stringify(true));
    banner.value = false;
}

onMounted(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("online", handleConnectionChange);
    let install = JSON.parse(localStorage.getItem("installLater"));
    if (
        !install &&
        !authStore.isIos &&
        !window.matchMedia("(display-mode: standalone)").matches
    ) {
        setTimeout(() => {
            banner.value = true;
        }, 10000);
    }
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
    window.removeEventListener("offline", handleConnectionChange);
    window.removeEventListener("online", handleConnectionChange);
});

// app.config.globalProperties.$offsetTop = offsetTop.value;
</script>

<style scoped>
/* .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    max-inline-size: 1400px;
    width: 100vw;
} */

.layout-page-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: fit-content;
}

.footer .content {
    /* height: 300px; */
    padding-left: 7%;
    padding-right: 7%;
}

.accelerate {
    transform: translateZ(0) !important;
    transform: translate3d(0, 0, 0) !important;
    will-change: transform, opacity;
}
</style>
