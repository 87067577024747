// import { DateTime } from "luxon";
import { dateTime } from "@/mixins";

const filters = {
    formatMoney(value) {
        return new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    },
    formatIdr(value) {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
        }).format(value);
    },
    toDate(value) {
        return dateTime(value).format("dddd, DD MMMM YYYY");
    },
    toDmy(value) {
        return dateTime(value).format("DD MMMM YYYY");
    },
    toDateTime(value) {
        return dateTime(value).format("dddd, DD MMMM YYYY, HH:mm");
    },
    toTime(value) {
        return DateTime.fromSQL(value)
            .setLocale("id")
            .toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    gender(value) {
        if (value == "L") {
            return "Laki-laki";
        } else {
            return "Perempuan";
        }
    },

    numberToText(num) {
        const ones = [
            "",
            "satu",
            "dua",
            "tiga",
            "empat",
            "lima",
            "enam",
            "tujuh",
            "delapan",
            "sembilan",
        ];
        const teens = [
            "",
            "sebelas",
            "dua belas",
            "tiga belas",
            "empat belas",
            "lima belas",
            "enam belas",
            "tujuh belas",
            "delapan belas",
            "sembilan belas",
        ];
        const tens = [
            "",
            "sepuluh",
            "dua puluh",
            "tiga puluh",
            "empat puluh",
            "lima puluh",
            "enam puluh",
            "tujuh puluh",
            "delapan puluh",
            "sembilan puluh",
        ];
        const hundreds = [
            "",
            "seratus",
            "dua ratus",
            "tiga ratus",
            "empat ratus",
            "lima ratus",
            "enam ratus",
            "tujuh ratus",
            "delapan ratus",
            "sembilan ratus",
        ];
        const thousands = [
            "",
            "seribu",
            "dua ribu",
            "tiga ribu",
            "empat ribu",
            "lima ribu",
            "enam ribu",
            "tujuh ribu",
            "delapan ribu",
            "sembilan ribu",
        ];

        let result = "";

        if (num >= 1000) {
            result += thousands[Math.floor(num / 1000)];
            num %= 1000;
        }

        if (num >= 100) {
            result += " " + hundreds[Math.floor(num / 100)];
            num %= 100;
        }

        if (num >= 20) {
            result += " " + tens[Math.floor(num / 10)];
            num %= 10;
        }

        if (num >= 10) {
            result += " " + teens[num - 10];
            num = 0;
        }

        if (num > 0) {
            result += " " + ones[num];
        }

        return result.trim();
    },
};

// Vue.filter('fulldate', function(value){
//     return moment(value).format('dddd, DD MMMM YYYY')
// });

export default filters;
