const menu = [
    {
        name: "sipengawas",
        icon: "icon-[ic--outline-dashboard]",
        title: "Dashboard",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "sipengawas.penugasan",
        icon: "icon-[ic--round-add-task]",
        title: "Penugasan",
        access: "pelaksana",
        type: "btn",
    },
    {
        icon: "icon-[tabler--database]",
        title: "Master",
        access: "pelaksana",
        type: "dropdown",
        list: [
            {
                name: "sipengawas.klien",
                icon: "icon-[mdi--account-group-outline]",
                title: "Klien",
                access: "pelaksana",
            },
            {
                name: "sipengawas.dasarPenugasan",
                icon: "icon-[mdi--task-auto]",
                title: "Dasar Penugasan",
                access: "pelaksana",
            },
            {
                name: "sipengawas.biaya",
                icon: "icon-[mdi--cash-fast]",
                title: "Biaya",
                access: "pelaksana",
            },
            {
                name: "sipengawas.level",
                icon: "icon-[tabler--user-up]",
                title: "Level User",
                access: "pelaksana",
            },
            {
                name: "sipengawas.itban",
                icon: "icon-[ic--outline-work-outline]",
                title: "ITBAN",
                access: "pelaksana",
            },
            // {
            //     name: "sipengawas.bagian",
            //     icon: "icon-[tabler--building-bank]",
            //     title: "Bagian",
            //     access: "pelaksana",
            // },
            {
                name: "sipengawas.jabatan",
                icon: "icon-[tabler--user-shield]",
                title: "Jabatan",
                access: "pelaksana",
            },
            {
                name: "sipengawas.pegawai",
                icon: "icon-[tabler--users]",
                title: "Pegawai",
                access: "pelaksana",
            },
            {
                name: "sipengawas.opd",
                icon: "icon-[ph--buildings]",
                title: "OPD/Unit",
                access: "pelaksana",
            },
            // {
            //     name: "sipengawas.hiperAktif",
            //     icon: "icon-[mdi--flash-alert-outline]",
            //     title: "Hiper Aktif",
            //     access: "pelaksana",
            // },
            {
                name: "sipengawas.jenis",
                icon: "icon-[mdi--shape-outline]",
                title: "Jenis",
                access: "pelaksana",
            },
            {
                name: "sipengawas.kegiatan",
                icon: "icon-[ic--outline-directions-run]",
                title: "Kegiatan",
                access: "pelaksana",
            },
            // {
            //     name: "sipengawas.resiko",
            //     icon: "icon-[ic--baseline-broken-image]",
            //     title: "Resiko",
            //     access: "pelaksana",
            // },
            // {
            //     name: "sipengawas.tingkatResiko",
            //     icon: "icon-[tabler--alert-hexagon]",
            //     title: "Tingkat Resiko",
            //     access: "pelaksana",
            // },
            // {
            //     name: "sipengawas.surat",
            //     icon: "icon-[tabler--mail]",
            //     title: "Surat",
            //     access: "pelaksana",
            // },
            // {
            //     name: "sipengawas.isuTerkini",
            //     icon: "icon-[tabler--news]",
            //     title: "Isu Terkini",
            //     access: "pelaksana",
            // },
            // {
            //     name: "sipengawas.anggaran",
            //     icon: "icon-[tabler--report-money]",
            //     title: "Anggaran",
            //     access: "pelaksana",
            // },
        ],
    },
];

export default menu;
